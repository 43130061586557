<template>
  <div class="lg:tw-mt-9 tw-mt-16 tw-p-4">
    <div class="tw-items-center tw-hidden md:tw-flex">
      <v-spacer/>
<!--      <div class="tw-flex tw-items-center tw-mr-4">-->
<!--        <vue-feather type="clock" style="color: #008EEF"></vue-feather>-->
<!--        <h5 class="timer">Set Timer</h5>-->
<!--      </div>-->
      <BaseButton :button-text="Boolean($route.query.isInvited)===true?'Submit Rating':'End Interview'" width="126px" :loading="loading" @click="$emit('open')"></BaseButton>

    </div>
    <div class="lg:tw-hidden">
      <div class="tw-flex tw-cursor-pointer tw-my-10" @click="$emit('desktop')">
      <vue-feather type="chevron-left"></vue-feather>
      <h4 class="back tw-ml-2">Back</h4>
    </div>
      <h2 class="name">Interview with {{candidateDetails.applicant.full_name}}</h2>
      <span class="job-details">{{evaluationCriteria.job_title}} | INVITED BY {{evaluationCriteria.invited_by}}</span>
    </div>

    <div class="comment-wrapper tw-w-full tw-my-9">
      <div class="comments ">
        <div class="tw-py-6">
          <v-tabs slider-color="#008EEF"  class="comment-title" v-model="tab"  grow>
            <v-tab> Comments</v-tab>
            <v-tab>Recommended Qsts</v-tab>
          </v-tabs>

          <v-divider style="padding: 0 !important;"/>
        </div>
        <component :is="displayComponent()"></component>

      </div>
    </div>
  </div>
</template>

<script>

import BaseButton from "@/components/UIComponents/BaseButton";
// import CommentComponent from "@/components/interview/CommentComponent";
import RecommendedInterviewQuestions from "@/components/interview/RecommendedInterviewQuestions";
import CommentComponent from "@/components/interview/CommentComponent";
export default {
name: "ConductInterviewComment",
  components: {RecommendedInterviewQuestions, BaseButton},
  props:{
  evaluationCriteria:[Array,Object],
    candidateDetails:[Object]
  },
  data(){
    return{
      message:'',
      comments:[],
      loading:false,
      tab:''
    }
  },
  methods:{
    displayComponent(){
      if(this.tab){
        return RecommendedInterviewQuestions
      }
      else {
        return CommentComponent
      }
    }
  }

}
</script>

<style scoped>
.timer{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #008EEF;
  margin-left: 6px;
}
.comment-wrapper{
  border: 1px solid #EAEAEA;
  border-radius: 12px;
}
.name{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
  margin-top: 15px;
}
.job-details{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #001343;
  margin-top: 7px;
  margin-bottom: 15px;
}

</style>
