<template>
  <div>
    <div class="grade-div md:tw-p-5 tw-p-1">
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
        <div>
          <h4>Evaluation Criteria</h4>
        </div>
        <div class="tw-flex">
          <div v-for="(data,i) in grades" :key="i" class="md:tw-mr-3 tw-mr-2">
            <h3 class="point">{{ data.point }}-{{ data.grade }}</h3>
          </div>

        </div>
      </div>
    </div>
    <div class="tw-mt-5">
      <v-row no-gutters >
        <v-col md="6" sm="12" v-for="(criteria, i) in evaluationCriteria.criteria" :key="i">
          <div class="tw-flex tw-justify-between tw-items-center tw-my-3 lg:tw-mr-12 md:tw-mr-12" >
            <div>
              <span class="criteria">{{criteria.title}}</span>
            </div>
            <div class="tw-flex" >

              <div  class="grade-box number tw-cursor-pointer" :class="{'selected-box': evaluation[criteria.title] === data.point}" v-for="(data,i) in grades"  :key="i" @click="selectGrade(criteria.title,data.point)">
                {{data.point}}
              </div>
            </div>

          </div>

        </v-col>

      </v-row>
      <div class="tw-my-4 lg:tw-hidden">
        <BaseButton :block="true" :button-text="Boolean($route.query.isInvited)===true?'Submit Rating':'End Interview'" :loading="loading" @click="$emit('open')"/>
      </div>
      <div style="position: fixed;bottom: 20px;right:15px" class="lg:tw-hidden"  @click="$emit('mobile')">
        <CommentIcon @click="$emit('mobile',true)"/>
      </div>

    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import CommentIcon from "@/components/dashboard/reuseables/CommentIcon";
import {mapGetters} from "vuex"
import {submitInterviewGrade} from "@/services/api/APIService";
export default {
  name: "InterviewEvaluationCriteriaGrade",
  components: {CommentIcon, BaseButton},
  props:{
    evaluationCriteria:[Array,Object]
  },
  data() {
    return {
      grades: [
        {
          grade: 'Excellent',
          point: 5
        },
        {
          grade: 'V. Good',
          point: 4
        },
        {
          grade: 'Good',
          point: 3
        },
        {
          grade: 'Poor',
          point: 2
        },
        {
          grade: 'V. Poor',
          point: 1
        }

      ],
      evaluation:{},
      loading:false
    }
  },
  watch: {
    evaluationCriteria: {
      handler: function ({criteria}) {
        this.evaluation = criteria.reduce((acc, curr) => ({...acc, [curr.title]: ''}), {})
      },
      immediate: true
    }
  },
  computed:{
    ...mapGetters('interview',['getInterviewGrade'])
  },

  methods: {
    selectGrade(data, i) {
      this.evaluation[data] = i
      this.$store.commit('interview/SET_INTERVIEW_GRADE',this.evaluation)
    },
    submit(){
      this.loading = true

      let data={
        interview:+this.$route.query.interview,
        candidate: this.$route.query.email,
        evaluation_score: this.getInterviewGrade
      }
      submitInterviewGrade(data)
      .then(res=>{
        console.log(res)
        this.$displaySnackbar({
          message: 'successful',
          success:true
        })
        this.$router.push(
            {
              name:'CandidateProfile',
              params:{id:this.$route.query.id},
              query:{jobId:this.$route.query.jobId,email:this.$route.query.email}})
      })
      .catch(err=>{
        console.log(err)
      })
      .finally(()=>this.loading=false)
    }
  },

}
</script>

<style scoped>
.grade-div {
  background: #FAFAFA;
  border-radius: 8px;
  /*padding: 17px;*/
}

.grade-div h3 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
}

.grade-div h4 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #001343;
}
.grade-box{
  border: 1.26923px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 5.07692px;
  /*padding: 12px;*/
  width: 33px;
  margin-right: 3px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-box{
  color: #8F47F4;
  border: 1.26923px solid #8F47F4 ;
}
.box{

}
.criteria{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
}
.number{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #001343;


}
.point{
  /*margin-right: 0.2px;*/
}
</style>
