<template>
  <div>
  <div class="tw-my-4 tw-mx-6">
    <v-select solo hide-details label="All Question" :items="getAllCriteria" v-model="criteria" :item-value="getAllCriteria.title">

    </v-select>
  </div>

  <div v-if="recommendedQuestions&&recommendedQuestions.length" class="scroll tw-px-2">
  <ul>
    <li v-for="(question, i) in getQuestionsBasedOnCriteria" :key="i">
      <ul class="question-list">
        <li v-for="(data, i) in question.questions" :key="i">{{data}}</li>

      </ul>
    </li>
  </ul>
  </div>
    <div v-else class="tw-mt-12 tw-p-6">
      <small>No interview recommended question yet</small>
    </div>
</div>
</template>

<script>
import {getInterviewRecommendQuestion} from "@/services/api/APIService";

export default {
name: "RecommendedInterviewQuestions",
  data(){
    return{
      recommendedQuestions:[],
      criteria:''
    }
  },
  computed:{
    getQuestionsBasedOnCriteria(){
      if(this.criteria && this.criteria !== 'All Criteria'){
        return this.recommendedQuestions.filter(e=>e.title===this.criteria)
      }
      else {
        return this.recommendedQuestions
      }
    },
    getAllCriteria(){
      let questions = []
      questions= this.recommendedQuestions.map(e => e.title)
      questions = questions.concat('All Criteria')
      return questions
    }

  },

  mounted() {
  this.getQuestion()
  },
  methods:{
    getQuestion(){
      getInterviewRecommendQuestion(this.$route.query.jobId)
      .then(res=>{
        console.log(res.data)
        this.recommendedQuestions = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.question-list{
  list-style: disc;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
li{
  margin-bottom: 15px !important;

}
.scroll{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}

</style>
