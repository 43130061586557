<template>
<div>
  <div v-if=" getInterviewComment&&getInterviewComment.length" class="scroll tw-px-6">
    <div v-for="(comment,i) in getInterviewComment" :key="i" class="tw-mt-6">
      <h5>{{comment.message}}</h5>
      <p>{{comment.created_by}}, {{formatDate(comment.created_at)}}</p>
    </div>
  </div>
  <div v-else class="tw-mt-12 tw-p-6">
    <small>No interview comment yet</small>
  </div>
  <div>
    <v-divider style="position: relative!important;"/>
    <div class="tw-p-3 tw-flex tw-items-center" @keyup.enter="sendInterviewComment">
      <v-text-field
          v-model="message"
          hide-details
          label="Enter your comment"
          solo

      />
      <div v-if="message" class="tw-ml-4" @click="sendInterviewComment">
        <img  alt="" src="@/assets/sendCommentIcon.svg"/>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import {sendInterviewComment} from "@/services/api/APIService";
import {mapGetters} from "vuex";

export default {
name: "CommentComponent",
  data(){
    return{
      message:'',
      comments:[],
    }
  },
  computed:{
    // eslint-disable-next-line no-undef
    ...mapGetters('interview',['getInterviewComment','getInterviewGrade'])
  },
  mounted() {
    this.getComment()
  },
  methods: {
    sendInterviewComment() {
      let application_type;
      application_type = 'recruit'
      if (this.message) {
        sendInterviewComment({
          interview_id: this.$route.query.interview,
          message: this.message,
          candidate: this.$route.query.email,
          interview_application: application_type
        })
            .then(res => {
              let newInterview = [...this.getInterviewComment, res.data]
              this.$store.commit('interview/SET_INTERVIEW_COMMENT', newInterview)
              this.message = ''
              console.log(res)
            })
            .catch(err => {
              console.log(err)
            })
      }
    },
    formatDate(date) {
      if (!date) return null;
      const newDate = date.split('T')[0]
      const [year, month, day] = newDate.split("-");
      return `${month}/${day}/${year}`;
    },
    getComment() {
      this.$store.dispatch('interview/getInterviewComment', this.$route.query.interview)
    },
  }
  }
</script>

<style scoped>
.comments{
  min-height: 450px;
}
.scroll{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
.comment-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #001343;
}
.comments h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
}
.comments p{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;
  margin-top: 4px !important;
  margin-bottom: 15px !important;
}

</style>
