<template>
<div>
  <div class="tw-flex tw-cursor-pointer" @click="$router.go(-1)">
    <vue-feather type="chevron-left"></vue-feather>
    <h4 class="back tw-ml-2">Back</h4>
  </div>
  <div>
    <h2 class="name">Interview with {{candidateDetails.applicant.full_name}}</h2>
    <span class="job-details">{{candidateDetails.job_name}} | INVITED BY {{evaluationCriteria.invited_by}}</span>
  </div>

  <div class="details-wrapper apply-applicant-interview-details">
    <div>
      <div class="tw-flex">
        <h4> • {{candidateDetails.job_name}}</h4>
        <h4> • {{candidateDetails.applicant.age}}</h4>
      </div>
      <div class="tw-flex">
        <h4> •  {{candidateDetails.applicant.education}} </h4>
        <h4> • {{candidateDetails.applicant.location}}</h4>
      </div>
    </div>
    <div class="tw-flex tw-mt-4 lg:tw-mt-0">
<!--      <div>-->
<!--        <h2>{{candidateDetails.score.evaluation}}</h2>-->
<!--        <span>Evaluation</span>-->
<!--      </div>-->
      <div class="tw-ml-6">
        <h2>{{candidateDetails.score.cognitive}}</h2>
        <span>Aptitude</span>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
name: "ConductInterviewApplicantDetails",
  props:{
    evaluationCriteria:[Array,Object],
    candidateDetails:[Object]
  },
  data(){
    return{
      applicantInterviewDetails:{
        cognitive:'0%',
        // evaluation:'0%',
        age:' 25 Years Old',
        qualification:'MSc',
        state:'Edo State',
        applicant_name:'Onowomano Iluezi-Ogbaudu',

      }
    }
  }
}
</script>

<style scoped>
.back{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #001343;
}
.name{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
  margin-top: 15px;
}
.job-details{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #001343;
  margin-top: 7px;
  margin-bottom: 15px;
}
.details-wrapper{
  background: rgba(197, 231, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

}
.details-wrapper h4{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 6px;

}
.details-wrapper h2{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.details-wrapper span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2B1C1C;
}

</style>
