<template>
  <div>
    <Header>
      <template #right-header-component>
        <ProfileDropDown/>
      </template>
    </Header>
    <Loader class="tw-mt-16" v-if="loading"/>
    <keep-alive  v-else>
    <component @open="dialog=true" :candidate-details="candidateDetails" :evaluation-criteria="evaluationCriteria" @mobile="changeToMobile()" @desktop="changeToDesktop()" v-bind:is="getIsMobile()"/>
    </keep-alive>
    <EndInterviewModal @close="dialog=false" :dialog="dialog"/>
  </div>

</template>

<script>
import ConductInterviewComment from "@/components/interview/ConductInterviewComment";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import ConductInterviewDesktopView from "@/views/interview/ConductInterviewDesktopView";
import {getInterviewEvaluationCriteria, getUserProfile} from "@/services/api/APIService";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import EndInterviewModal from "@/components/interview/EndInterviewModal";

export default {
  name: "ConductInterviewView",
  components: {
    EndInterviewModal,
    Loader,
    ProfileDropDown, Header,ConductInterviewComment,ConductInterviewDesktopView},
  data(){
    return{
      isMobile:false,
      evaluationCriteria:{},
      loading:false,
      candidateDetails:{},
      dialog:false
    }
  },
  created() {
    this.loading=true
    getInterviewEvaluationCriteria(this.$route.query.jobId,this.$route.query.interview)
        .then(res=>{
          console.log(res)
          this.evaluationCriteria = res.data
        })
        .catch(err=>{
          console.log(err)
        })
      .finally(()=>this.loading = false)
  },
  mounted() {
    this.getProfile()
  },
  methods:{
    getIsMobile(){
      if(this.isMobile){
        return ConductInterviewComment
      }
      else {
        return ConductInterviewDesktopView
      }
    },
    getProfile(){
      this.loader = true
      getUserProfile(this.$siteName(),this.$route.query.jobId,this.$route.query.email).then(res=> {
        console.log(res.data)
        this.candidateDetails = res.data
      })
          .catch(error=>{
            console.log(error)
          })
          .finally(()=>this.loader=false)
    },
    changeToMobile(){
      console.log('hjkkkkl')
      this.isMobile = true
    },
    changeToDesktop(){
      console.log('hjkkkkl')
      this.isMobile = false
    },

  }
}
</script>

<style scoped>

</style>
