<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.7197" cy="24.7197" r="24.7197" fill="#001343"/>
    <path d="M23.6797 16.6875H16.6797C16.1493 16.6875 15.6405 16.8982 15.2655 17.2733C14.8904 17.6484 14.6797 18.1571 14.6797 18.6875V32.6875C14.6797 33.2179 14.8904 33.7266 15.2655 34.1017C15.6405 34.4768 16.1493 34.6875 16.6797 34.6875H30.6797C31.2101 34.6875 31.7188 34.4768 32.0939 34.1017C32.469 33.7266 32.6797 33.2179 32.6797 32.6875V25.6875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.1797 15.1877C31.5775 14.7899 32.1171 14.5664 32.6797 14.5664C33.2423 14.5664 33.7819 14.7899 34.1797 15.1877C34.5775 15.5856 34.801 16.1251 34.801 16.6877C34.801 17.2503 34.5775 17.7899 34.1797 18.1877L24.6797 27.6877L20.6797 28.6877L21.6797 24.6877L31.1797 15.1877Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
name: "CommentIcon"
}
</script>

<style scoped>

</style>
