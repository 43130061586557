<template>
  <v-dialog
      transition="dialog-top-transition"
      width="450"
      v-model="dialog"
      persistent
  >
    <template>
      <v-card class="card">
        <div class="tw-flex title tw-items-center tw-justify-between">
          <h3 class="title">End Interview Session</h3>
          <span class="tw-cursor-pointer" @click="$emit('close')">X</span>
        </div>

        <div class="tw-my-8">
          <h4 class="card-text md:tw-w-80">You are about to end this interview session. Do you wish to proceed?</h4>

          <div class="tw-flex tw-mt-4">
            <v-spacer/>
            <BaseButton :outlined="true" @click="$emit('close')" class="tw-mr-2" width="75px" height="48px" button-text="No"/>
            <BaseButton :loading="loading" :depressed="true"  width="75px" height="48px" button-text="Yes" @click="submit"/>

          </div>
        </div>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {submitInterviewGrade} from "@/services/api/APIService";
import {mapGetters} from "vuex";
export default {
name: "EndInterviewModal",
  components: {BaseButton},
  props:{
    dialog:[Boolean],

  },
  data(){
  return{
    loading:false
  }
  },
  computed:{
    ...mapGetters('interview',['getInterviewGrade'])
  },
  methods:{
    submit(){
      this.loading = true

      let data={
        interview:+this.$route.query.interview,
        candidate: this.$route.query.email,
        evaluation_score: this.getInterviewGrade
      }
      submitInterviewGrade(data)
          .then(res=>{
            console.log(res)
            this.$displaySnackbar({
              message: 'successful',
              success:true
            })
            this.$emit('close')
            this.$router.push(
                {
                  name:'CandidateProfile',
                  params:{id:this.$route.query.id},
                  query:{jobId:this.$route.query.jobId,email:this.$route.query.email}})
          })
          .catch(err=>{
            console.log(err)
          })
          .finally(()=>this.loading=false)
    }

  }

}
</script>

<style scoped>
.card{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 15px;


}
.title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #001343;

}
.card-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;

}


</style>
