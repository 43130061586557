<template>
  <div class="applyFlexCenter  lg:tw-w-11/12 tw-p-4" style="margin: 80px auto 0;">
    <v-row no-gutters class="tw-flex-between">
      <v-col md="7" sm="12">
        <ConductInterviewApplicantDetails :candidate-details="candidateDetails" :evaluation-criteria="evaluationCriteria"/>
        <div v-if="Object.values(evaluationCriteria).length &&evaluationCriteria.criteria.length">
          <InterviewEvaluationCriteriaGrade  @open="$emit('open')" @close="$emit('close')" :evaluation-criteria="evaluationCriteria" @desktop="$emit('desktop')" @mobile="$emit('mobile')"/>
        </div>
        <div v-else class="tw-flex tw-mt-12 tw-items-center tw-justify-center tw-flex-col">
          <span class="no-evaluation">You haven’t set the <span class="tw-ml-1" style="font-weight: bolder"> Evaluation</span></span>
          <span  class="no-evaluation"><span style="font-weight: bolder" class="tw-mr-1">  Criteria </span> for this interview</span>
          <br>
          <br>
          <BaseButton button-text="Set Evaluation Criteria" @click="$router.push({name:'EvaluationCriteria',params:{id:$route.query.jobId},query:$route.query})" width="220px"/>
        </div>
      </v-col>
      <v-col md="1" sm="0" class="tw-hidden lg:tw-block"></v-col>
      <v-col md="4" sm="0" class="tw-hidden  lg:tw-block">
        <ConductInterviewComment @open="$emit('open')" @close="$emit('close')" :candidate-details="candidateDetails" :evaluation-criteria="evaluationCriteria"/>
      </v-col>

    </v-row>
  </div>

</template>

<script>
import ConductInterviewApplicantDetails from "@/components/interview/ConductInterviewApplicantDetails";
import InterviewEvaluationCriteriaGrade from "@/components/interview/InterviewEvaluationCriteriaGrade";
import ConductInterviewComment from "@/components/interview/ConductInterviewComment";
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
name: "ConductInterviewDesktopView",
  components: {BaseButton, ConductInterviewComment, InterviewEvaluationCriteriaGrade, ConductInterviewApplicantDetails},
  props:{
    evaluationCriteria:[Array,Object],
    candidateDetails:[Object]
  },
  methods:{
    mobile(status){
      this.$emit('mobile',status)
    }
  }
}
</script>

<style scoped>
.no-evaluation{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #001343;
}

</style>
